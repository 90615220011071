import React from 'react';
import { hackyServerSideTranslations } from '../i18nNext';
import { buildGuestOrLoggedInLayout } from '../components/layout/layoutBuilder';
import NotFound from '../components/auth/NotFound';

const NotFoundPage = () => {
  return <NotFound />;
};

export async function getStaticProps({ locale }) {
  const i18nConfig = await hackyServerSideTranslations(locale);
  return { props: { ...i18nConfig } };
}

NotFoundPage.getLayout = buildGuestOrLoggedInLayout();
export default NotFoundPage;
