import React from 'react';
import styled from 'styled-components';

const StyledIllustration = styled('svg')(() => ({
  left: 0,
  right: 0,
  bottom: '0',
  margin: 'auto',
  position: 'absolute',
  zIndex: -1,
  maxHeight: '46%',
  width: '100%'
}));

const WaveNotFound = () => {
  return (
    <StyledIllustration viewBox="0 0 1440 746" preserveAspectRatio="none">
      <defs>
        <mask
          id="prefix__wavenotfoundc"
          x={-46.04}
          y={-277.61}
          width={1776.01}
          height={1102.64}
          maskUnits="userSpaceOnUse">
          <path
            d="M-45.57-179.17C-32.15 20.14 88.79 164.06 190.61 164.06s223.9-63.11 332-40.16 194.09 84.17 284.16 95.85S1033.4 84 1186.92 84c112.62 0 180.6 106.46 258.07 163.2q28.14 20.61-5 577.83L0 824.85Q-50.68-255.31-45.57-179.17z"
            fill="#fff"
            fillRule="evenodd"
          />
        </mask>
        <mask
          id="prefix__wavenotfoundd"
          x={-155.97}
          y={-184.18}
          width={1613.88}
          height={1080.08}
          maskUnits="userSpaceOnUse">
          <path
            d="M-45.57-179.17C-32.15 20.14 88.79 164.06 190.61 164.06s223.9-63.11 332-40.16 194.09 84.17 284.16 95.85S1033.4 84 1186.92 84c112.62 0 180.6 106.46 258.07 163.2q28.14 20.61-5 577.83L0 824.85Q-50.68-255.31-45.57-179.17z"
            fill="#fff"
            fillRule="evenodd"
          />
        </mask>
        <linearGradient
          id="prefix__wavenotfounda"
          x1={470.19}
          y1={622.62}
          x2={470.19}
          y2={621.95}
          gradientTransform="matrix(1503.95 0 0 -1009.21 -706433.66 628678.79)"
          gradientUnits="userSpaceOnUse">
          <stop offset={0} stopColor="#21bc90" />
          <stop offset={1} stopColor="#35dbac" />
        </linearGradient>
        <filter id="prefix__wavenotfoundb" x="-9.4%" y="-14.1%" width="118.9%" height="128.1%">
          <feGaussianBlur result="shadowBlurInner1" stdDeviation={126.5} in="SourceAlpha" />
          <feOffset result="shadowOffsetInner1" dx={27} dy={31} in="shadowBlurInner1" />
          <feComposite
            result="shadowInnerInner1"
            operator="arithmetic"
            k2={-1}
            k3={1}
            in="shadowOffsetInner1"
            in2="SourceAlpha"
          />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" in="shadowInnerInner1" />
        </filter>
      </defs>
      <path
        d="M-45.57-179.17C-32.15 20.14 88.79 164.06 190.61 164.06s223.9-63.11 332-40.16 194.09 84.17 284.16 95.85S1033.4 84 1186.92 84c112.62 0 180.6 106.46 258.07 163.2q28.14 20.61-5 577.83L0 824.85Q-50.68-255.31-45.57-179.17z"
        fillRule="evenodd"
        fill="url(#prefix__wavenotfounda)"
      />
      <g filter="url(#prefix__wavenotfoundb)">
        <path
          d="M-45.57-179.17C-32.15 20.14 88.79 164.06 190.61 164.06s223.9-63.11 332-40.16 194.09 84.17 284.16 95.85S1033.4 84 1186.92 84c112.62 0 180.6 106.46 258.07 163.2q28.14 20.61-5 577.83L0 824.85Q-50.68-255.31-45.57-179.17z"
          fillRule="evenodd"
        />
      </g>
      <g mask="url(#prefix__wavenotfoundc)">
        <path
          d="M158.92 15.75C524.53 390.08 1232.35 764.31 1482.62 775q250.28 10.73 247.32-168Q601.22 359.65 290.81-11.33C-19.61-382.32-206.7-358.57 158.92 15.75z"
          fill="#d8d8d8"
          fillOpacity={0.19}
          fillRule="evenodd"
        />
      </g>
      <g mask="url(#prefix__wavenotfoundd)">
        <path
          d="M-78 224.62c190.33 248.87 364.4 582 550.34 600.38S1248 968.48 842 835.16-226.95 137.69-132.54-39.57-268.32-24.25-78 224.62z"
          fill="#d8d8d8"
          fillOpacity={0.19}
          fillRule="evenodd"
        />
      </g>
    </StyledIllustration>
  );
};
export default WaveNotFound;
