import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../atoms/Box';
import Button from '../atoms/Button';
import Img from '../atoms/Img';
import Text from '../atoms/Text';
import WaveNotFound from '../atoms/illustrations/WaveNotFound';
import commonRoutes from '../../routes/common';
import { useTranslate } from '../../translate/I18n';
import { assetize } from '../../utils/pureUtils';
import { useRouter } from '../../utils/router';

const StyledImage = styled(Img)(() => ({
  display: 'block',
  margin: 'auto',
  width: '100%',
  maxWidth: '750px'
}));

const NotFound = () => {
  const router = useRouter();
  const { t } = useTranslate(['notFound']);
  return (
    <Box overflow="hidden" zIndex="0" position="relative" minHeight="100vh">
      <Box textAlign="center" pt={6} px={3} mb={2}>
        <Box position="relative">
          <Text tag="h1" styleName="wH2" mb={2}>
            {t('notFound:title')}
          </Text>
          <Text styleName="wPNormal" mb={4}>
            {t('notFound:undertitle')}
          </Text>
          <Button
            shape="rounded"
            styleType="secondary"
            data-cy="button-go-home"
            onClick={() => router.push(commonRoutes.root.to)}>
            {t('notFound:backHome')}
          </Button>
        </Box>
      </Box>
      <StyledImage
        alt="not found illustration"
        src={assetize('/illustrations/NotFound.webp')}
        srcRetina={assetize('/illustrations/NotFound@2x.webp')}
        fallback={assetize('/illustrations/NotFound.png')}
        fallbackRetina={assetize('/illustrations/NotFound@2x.png')}
      />
      <WaveNotFound />
    </Box>
  );
};

NotFound.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func
};

export default NotFound;
